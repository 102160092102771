import React from "react"

const About = () => {  
  const emailAddress = "gabeporath@gmail.com";
    return (
        <div className="bg-D9D9D9 flex flex-row overflow-y-auto max-h-screen ">

          <div className="flex-row items-center overflow-y-auto pt-10 hide-scrollbar">

                        {/* 
              contact
            */}
            <div className="flex flex-col justify-center align-center items-center m-8">
              <h1 className="custom-font-text text-2xl p-3">
                CONTACT
              </h1>
              <h1 className="custom-font-text text-2xl p-3">
                GABEPORATH@GMAIL.COM
              </h1>
              <h1 className="custom-font-text text-2xl p-3 hover:underline">
                <a href={`mailto:${emailAddress}`} className="custom-font-text text-2xl p-3">
                  EMAIL FOR CV/RESUME
                </a>
              </h1>

            </div>

            {/* 
              descr.
            */}
            <div className="flex flex-col justify-center align-center items-center m-4 mt-8 pb-16">
              <h1 className="custom-font-text text-2xl">
                ABOUT
              </h1>
              <h1 className="custom-font-text text-2xl lg:w-1/3 text-center p-1">
              GABRIEL PORATH IS A BRAZILIAN-AMERICAN FILMMAKER BASED IN VANCOUVER AND LOS ANGELES, PRODUCING WORKS IN FILM, PHOTOGRAPHY, RADIO, AND UI/UX DESIGN.

              </h1>

            </div>


            


            {/* 
              photo
            */}
            <div className="flex flex-row justify-center items-center m-12 mt-0">
              <img
              className={`mx-auto my-auto border border-black border-4 max-w-[425px] md:w-1/2 w-3/4`}
              src={process.env.PUBLIC_URL + '/pageElements/AboutPhoto.jpg'}
              alt="logo"
              />
            </div>
            <div className="pb-64"></div>

            
            
          </div>
        </div>
    )
}

export default About
